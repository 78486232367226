import React from 'react';
/* eslint-disable max-len */
export const GithubIcon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="#9ca3af"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4083 3.4091C17.185 1.93182 16.2974 0 16.2974 0C14.3001 0 12.8577 1.36363 12.8577 1.36363C12.081 0.909079 9.52903 0.909079 9.52903 0.909079C9.52903 0.909079 6.97704 0.909079 6.20035 1.36363C6.20035 1.36363 4.75792 0 2.76071 0C2.76071 0 1.87305 1.93182 2.64975 3.4091C2.64975 3.4091 0.874453 5.11365 1.54018 8.75001C2.16577 12.1671 5.09077 13.0682 6.97702 13.0682C6.97702 13.0682 6.20033 13.75 6.31129 14.8864C6.31129 14.8864 5.20173 15.5682 4.09217 15.1137C2.98261 14.6591 2.42782 13.5227 2.42782 13.5227C2.42782 13.5227 1.31826 12.0455 0.2087 12.6136C0.2087 12.6136 -0.124166 12.9545 1.09636 13.5227C1.09636 13.5227 1.98401 14.8864 2.31688 15.6818C2.64974 16.4773 4.42504 17.1591 6.20035 16.7045V19.3182C6.20035 19.3182 6.20035 19.5454 5.75652 19.6591C5.31269 19.7727 5.31269 20 5.53462 20H13.5234C13.7453 20 13.7453 19.7727 13.3015 19.6591C12.8577 19.5454 12.8577 19.3182 12.8577 19.3182V16.7045C12.8577 16.7045 12.8675 15.3417 12.8577 14.8864C12.8333 13.7446 12.081 13.0682 12.081 13.0682C13.9673 13.0682 16.8923 12.1671 17.5179 8.74999C18.1836 5.11365 16.4083 3.4091 16.4083 3.4091Z"
        className="fill-gray-400"
      />
    </svg>
  );
};
