import React from 'react';
/* eslint-disable max-len */
export const TelegramIcon = () => {
  return (
    <svg
      width="25"
      height="20"
      viewBox="0 0 25 20"
      fill="#9ca3af"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.0915 18.79L9.46694 13.1963L19.7646 4.04528C20.2204 3.63534 19.6707 3.43698 19.0673 3.79403L6.3562 11.7152L0.858767 9.99607C-0.321169 9.66547 -0.334578 8.85881 1.12693 8.27696L22.5401 0.130983C23.5189 -0.305408 24.4575 0.369015 24.0821 1.8501L20.435 18.79C20.1802 19.9934 19.4428 20.2843 18.4237 19.7289L12.8727 15.6824L10.2044 18.2346C9.896 18.5388 9.64124 18.79 9.0915 18.79Z"
        className="fill-gray-400"
      />
    </svg>
  );
};
